import React from 'react'
import { Container } from '../styles/globalStyles/globalStyles'
import { ContactForm } from '../components'
import { pageAnimation } from '../styles/Animations/animations'
import styled from 'styled-components'

export default function Contacts() {
  return (
    <>
      <ContactsContainer
        variants={pageAnimation}
        initial='hidden'
        animate='show'
        exit='exit'>
        <ContactForm />
      </ContactsContainer>
    </>
  )
}

const ContactsContainer = styled(Container)`
  @media (max-width: 1500px) {
    display: flex;
    flex-direction: column;
  }
`
