import React from 'react'
import { InfoSection } from '../components'
import { homeContents } from '../helpers/Data'
import { Container, Hide } from '../styles/globalStyles/globalStyles'
import {
  transition,
  pageAnimation,
  titleAnimation,
} from '../styles/Animations/animations'
import { motion } from 'framer-motion'

export default function Home() {
  return (
    <>
      <Container
        transition={transition}
        variants={pageAnimation}
        exit='exit'
        initial='hidden'
        animate='show'>
        <Hide>
          <motion.h1 variants={titleAnimation}>
            Welcome to Our Website
          </motion.h1>
        </Hide>
        {homeContents.map((content) => (
          <InfoSection key={content.id} content={content} />
        ))}
      </Container>
    </>
  )
}
