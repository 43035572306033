import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.div`
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
`

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`

export const FooterSubHeading = styled.p`
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  margin-bottom: 24px;
  font-size: 24px;
`

export const FooterSubText = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
`

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`

export const FooterLinksContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
    align-items: center;
    flex-wrap: wrap;
  }
`

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: space-evenly;

  /* @media screen and (max-width: 820px) {
    flex-direction: column;
  } */
`

export const FooterLinksItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 180px;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FooterLinkTitle = styled.h2`
  margin-bottom: 16px;
`

export const FooterLinkTitleIcon = styled.span`
  margin-right: 5px;
`

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #cd853f;
    transition: 0.3s ease-out;
  }
`

export const FooterALink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #cd853f;
    transition: 0.3s ease-out;
  }
`

export const SocialMedia = styled.section`
  width: 100%;
  max-width: 1000px;
`

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  color: #ffffff;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const FooterLogo = styled(Link)`
  color: #ffffff;
  justify-self: start;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:hover {
    color: #cd853f;
  }
`
export const WebsiteRights = styled.small`
  color: #ffffff;
  margin-bottom: 16px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`

export const SocialIconLink = styled.a`
  color: #ffffff;
  font-size: 24px;
  &:hover {
    color: #cd853f;
  }
`

export const ContactsDiv = styled.div`
  display: flex;
  margin: 5px;
`

export const ContactsIcon = styled.i`
  margin-right: 10px;
`

export const CreatedLink = styled.a`
  text-decoration: none;
  color: #cd853f;
  margin-left: 5px;

  &:hover {
    color: blue;
  }
`
