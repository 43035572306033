import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RiWaterFlashFill } from 'react-icons/ri'
import { devices } from '../../styles/globalStyles/device'

export const Nav = styled.nav`
  background-color: #000;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 100;

  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 1rem 0rem;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
`

export const NavLogo = styled(Link)`
  color: #fff;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
`

export const Name = styled.div`
  display: block;

  @media ${devices.tabletL} {
    display: none;
  }
`

export const Init = styled.div`
  display: none;

  @media ${devices.tabletL} {
    display: block;
  }
`

export const NavIcon = styled(RiWaterFlashFill)`
  margin-right: 0.5rem;
`

export const MobileIcon = styled.div`
  display: none;
  color: #cd853f;

  @media ${devices.tabletL} {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media ${devices.tabletL} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease-in-out;
    background: #101522;
  }
`

export const NavItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 12px solid #cd853f;
    color: #cd853f;
  }

  @media ${devices.tabletL} {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    color: #cd853f;
  }

  @media ${devices.tabletL} {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #cd853f;
      transition: all 0.3s ease;
    }
  }
`
