import { motion } from 'framer-motion'
import { FiSend } from 'react-icons/fi'
import styled from 'styled-components'
import { Button } from '../../styles/globalStyles/globalStyles'

export const ContactFormContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(234, 237, 237);

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
`

export const H1 = styled(motion.h1)`
  display: flex;
  align-items: center;
  font-weight: 500px;
  margin-bottom: 20px;
  color: rgb(0, 0, 32);
`

export const FormIcon = styled(motion.span)`
  margin-right: 10px;
`

export const Label = styled(motion.label)`
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
`

export const ContactFormDiv = styled(motion.div)`
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
`

export const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
`

export const Input = styled(motion.input)`
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  &::placeholder {
    color: lightgray;
  }
`

export const TextArea = styled(motion.textarea)`
  height: 150px;
  max-width: 400px;
  min-height: 100px;
  margin-bottom: 24px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  &::placeholder {
    color: lightgray;
  }
`

export const FormButton = styled(Button)`
  width: 90%;
`

export const ButtonIcon = styled(motion.span)`
  font-size: 16px;
  margin-right: 5px;
`

export const Contacts = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(10px, 2vw, 2rem);
  color: rgb(0, 0, 32);
  margin-top: 20px;
`

export const SendButtonIcon = styled(FiSend)`
  color: red;
`
