import { motion } from 'framer-motion'
import React from 'react'
import { InfoSection } from '../components'
import { homeContents } from '../helpers/Data'
import { pageAnimation, titleAnimation } from '../styles/Animations/animations'
import { Container, Hide } from '../styles/globalStyles/globalStyles'

export default function About() {
  return (
    <>
      <Container
        variants={pageAnimation}
        initial='hidden'
        animate='show'
        exit='exit'>
        <Hide>
          <motion.h1 variants={titleAnimation}>ABOUT US</motion.h1>
        </Hide>
        {homeContents.map((content) => (
          <InfoSection key={content.id} content={content} />
        ))}
      </Container>
    </>
  )
}

