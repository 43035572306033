import { Route, Switch, useLocation } from 'react-router-dom'
import { Footer, Header } from './components'
import GlobalStyle from './styles/globalStyles/globalStyles'
import { About, Contacts, Home } from './pages'
import ScrollToTop from './helpers/ScrollToTop'
import { AnimatePresence } from 'framer-motion'

function App() {
  let location = useLocation()

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path='/pages/About.js' component={About} />
          <Route exact path='/pages/Contacts.js' component={Contacts} />
          <Route exact path='/' component={Home} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default App
