import imgOne from '../images/img-1.jpg'
import imgTwo from '../images/img-2.jpg'
import imgThree from '../images/img-3.jpg'
import imgFour from '../images/img-4.jpg'

export const homeContents = [
  {
    id: '1',
    name: 'Amazon-Clone',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'Wedding',
    lightTextDesc: true,
    description:
      'Hewad Group is the only Afghan Dance group that can attend and dance in your wedding parties, celebrations, concerts and clips.',
    topLine: 'Commitment Time',
    start: '',
    img: imgOne,
    alt: 'Hewad Group',
  },
  {
    id: '2',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'هیواد ګروپ',
    lightTextDesc: false,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'هیواد ګروپ کولی شي ستاسو په کنسرتونو، کلیپونو او وادونو کې ګدون وکړي. زمونږ سره اړیکي ونیسئ',
    topLine: 'کنسرتونه',
    RightSideText: true,
    start: '',
    img: imgFour,
    alt: 'کنسرتونو',
  },

  {
    id: '3',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'Hewad Group',
    lightTextDesc: true,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description: `Die Hewad Group ist die einzige afghanische Tanzgruppe, mit der Sie Ihre Hochzeiten sowie Konzerte und Video Clips bunter gestalten können.
     Für weitere Infos können Sie uns gerne kontaktieren.`,
    RightSideText: false,
    topLine: 'Video Time',
    start: 'true',
    img: imgThree,
    alt: 'Hewad Group',
  },
  {
    id: '4',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'محافل',
    lightTextDesc: false,
    description: `هیواد گروپ یگانه گروپ رقص افغانی است که به دعوت شما عزیزان میتواند به کنسرت ها. کلیپ های هنرمندان و محافل خوشی شما عزیزان شرکت کند. شما میتوانید با ما به تماس شوید`,
    RightSideText: true,
    topLine: ' محافل خوشی',
    start: '',
    img: imgTwo,
    buttonLink: '/projects',
    alt: 'Hewad Group',

    videoUrl: 'https://www.youtube.com/watch?v=0DAmWHxeoKw&ab_channel=Netflix',
    style: {
      marginLeft: '-10px',
    },
    aboutTheProject: `This is a full responsive website in any devices. By using react-youtube & movie-trailer dependency, the application is able to play the trailer.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, strip-js, react-scripts, styled-components, react-youtube',
  },
]
