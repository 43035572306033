import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { devices } from './device'
import { motion } from 'framer-motion'

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: open-'Trebuchet MS', 'Lucida Sans', 'Unicode' 'Source Sans Pro',  'Lucida Grande', 'Lucida Sans', Arial, sans-serif, 
  }


img {
  object-fit: cover;
}
`

export const Hide = styled(motion.div)`
  overflow: hidden;
`

export const Title = styled(motion.h1)

export const Container = styled(motion.div)`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media ${devices.mobileS} {
    flex-direction: column;
    max-width: 320px;
  }

  @media screen and (max-width: 991px) {
  }
`
export const Button = styled(motion.button)`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background: ${({ primary }) => (primary ? '#000D1A' : '#CD853F')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: ${({ primary }) => (primary ? '#000D1A' : '#fff')};
  font-size: clamp(1rem, 4vw, 1.5rem);
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: ${({ marginRight }) => (marginRight ? '10px ' : '0px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? '0px' : '10px')};

  &:hover {
    transition: all 0.3s ease-out;
    color: ${({ primary }) => (primary ? '#000D1A' : '#CD853F')};
    background: ${({ primary }) => (primary ? '#CD853F' : '#000D1A')};
    border: 1px solid ${({ primary }) => (primary ? '#000D1A' : '#CD853F')};
  }

  @media ${devices.mobileS} {
    width: 100%;
    margin-top: 10px;
  }

  @media ${devices.tabletM} {
  }

  @media ${devices.tabletL} {
    width: 100%;
    align-items: center;
    margin-top: 10px;
  }
`

export const Links = styled(Link)`
  text-decoration: none;
  color: #fff;
`

export default GlobalStyle

export const Span = styled(motion.span)`
  font-weight: bold;
  color: #cd853f;
  margin-right: 10px;
  display: flex;
  justify-content: center;
`
