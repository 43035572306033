import React from 'react'
import {
  fade,
  photoAnimation,
  titleAnimation,
} from '../../styles/Animations/animations'
import { Container, Hide } from '../../styles/globalStyles/globalStyles'
import {
  Heading,
  Headline,
  Img,
  ImgWrapper,
  InfoColumn,
  InfoRow,
  InfoSec,
  Description,
  TextWrapper,
  TopLine,
} from './InfoSection.elements'

function InfoSection({
  content: {
    lightBg,
    imgStart,
    lightTopLine,
    lightText,
    headLine,
    lightTextDesc,
    description,
    topLine,
    start,
    img,
    alt,
    style,
    RightSideText,
  },
}) {
  return (
    <InfoSec lightBg={lightBg}>
      <Container>
        <InfoRow imgStart={imgStart}>
          <InfoColumn>
            <TextWrapper RightSideText={RightSideText}>
              <Hide>
                <TopLine
                  RightSideText={RightSideText}
                  lightTopLine={lightTopLine}
                  variants={titleAnimation}>
                  {topLine}
                </TopLine>
              </Hide>
              <Hide>
                <Heading
                  variants={titleAnimation}
                  RightSideText={RightSideText}
                  lightText={lightText}>
                  <Headline lightText={lightText} style={style}>
                    {headLine}
                  </Headline>
                </Heading>
              </Hide>
              <Description variants={fade} lightTextDesc={lightTextDesc}>
                {description}
              </Description>
            </TextWrapper>
          </InfoColumn>
          <InfoColumn>
            <ImgWrapper start={start}>
              <Img variants={photoAnimation} src={img} alt={alt} />
            </ImgWrapper>
          </InfoColumn>
        </InfoRow>
      </Container>
    </InfoSec>
  )
}

export default InfoSection
