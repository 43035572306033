import React, { useState } from 'react'

import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLink,
  FooterLinksItems,
  FooterSubHeading,
  FooterSubscription,
  FooterSubText,
  FooterLinkTitle,
  SocialMedia,
  SocialMediaWrap,
  SocialIcons,
  WebsiteRights,
  FooterLinkTitleIcon,
  ContactsDiv,
  ContactsIcon,
  CreatedLink,
  SocialIconLink,
  FooterLogo,
} from './Footer.elements'

import {
  FaCopyright,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaPhone,
} from 'react-icons/fa'
import { MdFindInPage, MdEmail } from 'react-icons/md'
import { SiTiktok } from 'react-icons/si'
import { TiContacts } from 'react-icons/ti'
import { Button, Links } from '../../styles/globalStyles/globalStyles'

function Footer() {
  const [copyRight] = useState(new Date().getFullYear())

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>The Only Afghan Dance Group</FooterSubHeading>

        <FooterSubText>Send us email at any time.</FooterSubText>

        <Links to='../../pages/Contacts.js'>
          <Button fontBig>CONTACT US</Button>
        </Links>
      </FooterSubscription>

      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <MdFindInPage />
              </FooterLinkTitleIcon>
              Pages
            </FooterLinkTitle>
            <FooterLink to='/'>Home</FooterLink>
            <FooterLink to='../../pages/About.js'>About Us</FooterLink>
            <FooterLink to='../../pages/Contacts.js'>Contacts</FooterLink>
          </FooterLinksItems>
          {/*  */}
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <TiContacts />
              </FooterLinkTitleIcon>
              Contacts
            </FooterLinkTitle>
            <ContactsDiv>
              <ContactsIcon>
                <FaPhone />
              </ContactsIcon>
              +49 176 6338 2850
            </ContactsDiv>
            <ContactsDiv>
              <ContactsIcon>
                <MdEmail />
              </ContactsIcon>
              ajmal.farahi005@gmail.com
            </ContactsDiv>
          </FooterLinksItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>

      <SocialMedia>
        <SocialMediaWrap>
          <FooterLogo to='/'>HEWAD GROUP</FooterLogo>

          <WebsiteRights>
            HEWAD GROUP <FaCopyright /> {copyRight}
          </WebsiteRights>
          <WebsiteRights>
            Website Created By
            <CreatedLink href='https://www.dzirmal.com' target='_blank'>
              ZRRAWAR DZIRMAL
            </CreatedLink>
          </WebsiteRights>

          <SocialIcons>
            <SocialIconLink
              href='https://www.facebook.com/hewadgroupofficial'
              target='_blank'
              aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>

            <SocialIconLink
              href='https://www.instagram.com/hewadgroupofficial'
              target='_blank'
              aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>

            <SocialIconLink
              href={'http://youtube.com/hewadgroupofficial'}
              target='_blank'
              aria-label='YouTube'
              rel='noopener noreferrer'>
              <FaYoutube />
            </SocialIconLink>

            <SocialIconLink
              href='https://www.tiktok.com/@hewadgroupofficial'
              target='_blank'
              aria-label='Tik Tok'>
              <SiTiktok />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  )
}

export default Footer
