import { useRef } from 'react'
import {
  ButtonIcon,
  ContactFormContainer,
  ContactFormDiv,
  Contacts,
  Form,
  FormButton,
  FormIcon,
  H1,
  Input,
  Label,
  TextArea,
} from './ContactForm.elements'
import { FiMail, FiSend } from 'react-icons/fi'

import emailjs from 'emailjs-com'
import { init } from 'emailjs-com'
import { FaPhone } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { titleAnimation } from '../../styles/Animations/animations'
import { Hide, Span } from '../../styles/globalStyles/globalStyles'
init('user_oHS0zfPL3E6Fqlr4p0jj8')

function ContactForm() {
  const inputRef = useRef(null)

  const sendEmail = (e) => {
    e.preventDefault()
    inputRef.current.focus()

    emailjs
      .sendForm(
        'service_c7s97z1',
        'template_3wnxpic',
        e.target,
        'user_oHS0zfPL3E6Fqlr4p0jj8'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )

    e.target.reset()
  }

  return (
    <>
      <ContactFormContainer>
        <Contacts>
          <Hide>
            <H1 variants={titleAnimation}>
              <Span>
                <FaPhone />
              </Span>
              +49 176 63382850
            </H1>
          </Hide>
          <Hide>
            <H1 variants={titleAnimation}>
              <Span>
                <MdEmail />
              </Span>
              ajmal.farahi005@gmail.com
            </H1>
          </Hide>
        </Contacts>
        <ContactFormDiv onSubmit={sendEmail}>
          <Hide>
            <H1 variants={titleAnimation}>
              <FormIcon>
                <Span>
                  <FiMail />
                </Span>
              </FormIcon>
              Send email
            </H1>
          </Hide>
          <Form>
            <Hide>
              <Label>Your Name</Label>
            </Hide>
            <Hide>
              <Input
                required
                placeholder='Your Name'
                type='text'
                ref={inputRef}
                name='name'
                variants={titleAnimation}
              />
            </Hide>
            <Hide>
              <Label>Your E-mail</Label>
            </Hide>
            <Hide>
              <Input
                required
                placeholder='Your E-mail'
                type='email'
                name='email'
                variants={titleAnimation}
              />
            </Hide>
            <Hide>
              <Label>Subject</Label>
            </Hide>
            <Hide>
              <Input
                required
                placeholder='The Subject'
                type='text'
                name='subject'
                variants={titleAnimation}
              />
            </Hide>
            <Hide>
              <Label>Message</Label>
            </Hide>
            <Hide>
              <TextArea
                required
                placeholder='Your Message'
                type='text'
                name='message'
                variants={titleAnimation}
              />
            </Hide>
            <Hide>
              <FormButton variants={titleAnimation}>
                <ButtonIcon>
                  <FiSend />
                </ButtonIcon>
                SEND
              </FormButton>
            </Hide>
          </Form>
        </ContactFormDiv>
      </ContactFormContainer>
    </>
  )
}

export default ContactForm
