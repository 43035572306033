import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

import {
  Nav,
  NavItem,
  NavMenu,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavLinks,
  Init,
  Name,
} from './Header.elements'

function Header() {
  const [click, setClick] = useState(false)

  const NavbarMenu = [
    { title: 'Home', link: '/' },
    { title: 'About Us', link: '../../pages/About.js' },
    { title: 'Contacts', link: '../../pages/Contacts.js' },
  ]

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to='/' onClick={closeMobileMenu}>
            <Name>HEWAD GROUP</Name>
            <Init>HG</Init>
          </NavLogo>

          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            {NavbarMenu.map((item) => {
              return (
                <NavItem key={item.title}>
                  <NavLinks to={item.link}>{item.title}</NavLinks>
                </NavItem>
              )
            })}

            {/* <NavItem>
              <NavLinks to='/'>Home</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to='../../pages/About.js'
                onClick={() => closeMobileMenu()}>
                About Us
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to='../../pages/Contacts.js'
                onClick={() => closeMobileMenu()}>
                Contacts
              </NavLinks>
            </NavItem> */}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Header
